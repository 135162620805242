/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import {
  get,
  post,
  put,
  updateCSRFToken,
  updateToken,
} from "../../../helper/api_helper";
import { generateSignature, showToast } from "../../../helper/common";
import { authSlice } from "./slice";
import { socket } from "../../../utils/socket";
import { getValuesSocketData } from "../../../helper/secretManager";
import axios from "axios";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await get(URL.USER_DETAILS, { signature, nonce });
      if (response?.status_code === 405) {
        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails());
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);
      }
      if (response?.status === "failure") {
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      // showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "updateProfile",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await put(URL.UPDATE_PROFILE + data?._id, data, {
        signature,
        nonce,
      });
      callBack && callBack(null, response?.data);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getCsrfToken = createAsyncThunk(
  "getCsrfToken",
  async ({ callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await get(URL.GET_CSRF_TOKEN, { signature, nonce });
      if (response.status === "failure") {
        showToast(response?.message, "error");
        return Thunk.rejectWithValue(response?.message);
      }
      updateCSRFToken(response.data.token);
      // localStorage.setItem("authUser", JSON.stringify(response.data))
      // localStorage.setItem("authToken", response.data.authToken)
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");

      callBack && callBack(error?.message || "Something went wrong!");

      return Thunk.rejectWithValue(error);
    }
  }
);

export const telegramLogin = createAsyncThunk(
  "telegramLogin",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.LOGIN_USER, data, { signature, nonce });
      if (response?.status === "failure") {
        showToast(response?.message, "error", { autoClose: 30000 });
        callBack && callBack(response);
        return Thunk.rejectWithValue({ message: response?.message });
      }
      callBack && callBack(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error", {
        autoClose: 30000,
      });
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const userSession = createAsyncThunk(
  "userSession",
  async ({ callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await get(URL.USER_SESSION, { signature, nonce });
      if (response.status === "failed") {
        callBack && callBack(response?.message, "");
      }
      callBack && callBack("", response?.data);
      return response;
    } catch (error) {
      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const userSessionLogout = createAsyncThunk(
  "userSessionLogout",
  async ({ callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await post(URL.USER_LOGOUT, {}, { signature, nonce });
      if (response.status === "failed") {
        callBack && callBack(response?.message, "");
        return Thunk.rejectWithValue(response?.message);
      }
      callBack && callBack("", response);
      return response;
    } catch (error) {
      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const checkBitanicaLinkApi = createAsyncThunk(
  "checkBitanicaLinkApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      const response = await get(URL.CHECK_BITANICA_LINKING, {
        signature,
        nonce,
      });
      if (response?.status === "failure") {
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }

      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const telegramBotAutoLogin = createAsyncThunk(
  "telegramBotAutoLogin",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      const response = await post(URL.USER_BOT_LOGIN, data, {
        signature,
        nonce,
      });
      if (response?.status === "failure") {
        showToast(response?.message, "error", { autoClose: 30000 });
        callBack && callBack(response, null);
        return Thunk.rejectWithValue({ message: response?.message });
      }
      callBack && callBack(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error", {
        autoClose: 30000,
      });
      callBack && callBack(error?.message || "Something went wrong!");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const generateTelLoginQrApi = createAsyncThunk(
  "generateTelLoginQrApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await get(URL.GENERATE_TEL_LOGIN_QR, {
        signature,
        nonce,
      });
      if (response?.status === "failure") {
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }

      callBack && callBack(null, response?.data);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const fetchUserIp = createAsyncThunk(
  "fetchUserIp",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await axios.get(URL.USER_IP);
      console.log(response, "RESPONSE&&&&&");
      callBack && callBack(null, response?.data);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error.message);
    }
  }
);
