import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoaderCstm from "../Loader";
import { wait } from "../../helper/common";
import SplashScreen from "../Splash";

// PrivateRoute component as a wrapper for protected routes
const GameEnterRoute = ({ sessionLoader, loginLoader,timeoutDone, children }) => {
    //console.info("gamew")
    const { user, loading } = useSelector(({ Auth }) => Auth);

    // While checking auth, show a loading indicator
    if ((sessionLoader || loginLoader || loading) && !timeoutDone) {
        return <SplashScreen />;
    }

    // wait(500);
    
    // If authenticated, render the child component (protected content)
    if (!user?._id && !sessionLoader && !loginLoader && !loading) {
        return children;
    }

    // While checking auth, show a loading indicator
    if (user?._id && !sessionLoader && !loginLoader && !loading) {
        return <Navigate to="/game" />;
    } else {
        return <SplashScreen />;
    }
    // If not authenticated, redirect to the login page
};

export default GameEnterRoute;
