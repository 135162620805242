import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Nav, Row, Spinner, Tab } from 'react-bootstrap'
import styles from "../wallet.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { claimBalanceApi, gameClaimHistoryApi, getUserProfile } from '../../../../lib/store/actions';
import moment from "moment";
import { wait } from '../../../../helper/common';
import LoaderCstm from '../../../../components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
// import useUserBalance from '../../../../hooks/useUserBalance';
import LinkAccountPop from '../../../../components/Modals/LinkAccountPop';
import { useGameContext } from '../../../../context/GameContext';
import { useWindow } from '../../../../hooks/useWindow';


const lock = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81832 8.45616C5.07671 8.45616 4.47563 7.88833 4.47563 7.18774C4.47563 6.63597 4.85069 6.17173 5.37076 5.99711V3.80527C5.37076 3.57188 5.57082 3.38247 5.81832 3.38247C6.06582 3.38247 6.26588 3.57188 6.26588 3.80527V5.99711C6.78595 6.17173 7.16101 6.63597 7.16101 7.18774C7.16101 7.88833 6.55993 8.45616 5.81832 8.45616ZM6.2614 1.71237L6.26588 0.845616H8.05614C8.30364 0.845616 8.5037 0.656621 8.5037 0.422808C8.5037 0.188995 8.30364 0 8.05614 0H3.58051C3.333 0 3.13294 0.188995 3.13294 0.422808C3.13294 0.656621 3.333 0.845616 3.58051 0.845616H5.37076V1.69123L5.37524 1.71237C2.36985 1.92715 0 4.29319 0 7.18774C0 10.2235 2.60482 12.6842 5.81832 12.6842C9.03182 12.6842 11.6366 10.2235 11.6366 7.18774C11.6366 4.29319 9.26679 1.92715 6.2614 1.71237Z" fill="#54B3F5" />
</svg>


const wave = <svg xmlns="http://www.w3.org/2000/svg" width="379" height="48" viewBox="0 0 379 48" fill="none">
    <path opacity="0.3" d="M68.9091 24.4145C54.3198 23.0043 47.9672 18.2875 34.4545 17.8462C24.6722 17.5266 15.962 19.63 8.30353 22.3392C3.20356 24.1434 0 29.1161 0 34.5258C0 41.9674 6.03259 48 13.4742 48H368.419C374.263 48 379 43.2629 379 37.4194C379 25.7505 360.527 23.149 349.902 27.9735C348.16 28.7643 346.373 29.1992 344.545 29.1992C328.449 29.1992 324.519 4.58455 310.091 1.19243C295.663 -2.19968 288.988 13.7199 275.636 14.934C262.285 16.1481 251.68 10.6748 241.182 8.9552C230.684 7.23563 219.217 4.48081 206.727 7.83154C194.238 11.1823 187.723 33.2593 172.273 36.9102C156.822 40.561 151.6 29.1992 137.818 27.4196C124.036 25.6401 117.845 26.4078 103.364 25.8247C88.882 25.2416 83.4984 25.8247 68.9091 24.4145Z" fill="url(#paint0_linear_1_725)" />
    <defs>
        <linearGradient id="paint0_linear_1_725" x1="-5.45682" y1="14.5714" x2="229.179" y2="216.458" gradientUnits="userSpaceOnUse">
            <stop stop-color="#43BFFF" stop-opacity="0" />
            <stop offset="0.54" stop-color="#00EFC0" />
            <stop offset="0.962409" stop-color="#00EFC0" stop-opacity="0" />
        </linearGradient>
    </defs>
</svg>

const CryptoHistory = () => {
    const { setGameEnergyLeft, setBtncBalance } = useGameContext();

    const dispatch = useDispatch();
    const { user, claimHistoryList, totalClaimHisotory, loadingClaimHistory, binaticaLoading, claimLoading, isBitanicaLinked, loadingBitanicLink } = useSelector(({ Auth, Game }) => ({
        user: Auth.user,
        claimHistoryList: Game.claimHistoryList,
        totalClaimHisotory: Game.totalClaimHisotory,
        loadingClaimHistory: Game.loadingClaimHistory,
        binaticaLoading: Game.binaticaLoading,
        claimLoading: Game.claimLoading,
        isBitanicaLinked: Auth.isBitanicaLinked,
        loadingBitanicLink: Auth.loadingBitanicLink,
    }));
    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        order: -1,
        orderBy: "date_created_utc",
        search: "",
        status: "",
    });
    const [linkAccount, setLinkAccount] = useState();
    const [rowData, setRowData] = useState(null);
    const [scrollStopOn, setScrollStopOn] = useState(false);

    useEffect(() => {
        if (user?._id) {
            dispatch(gameClaimHistoryApi({ data: filter, userId: user?._id }));
        }
    }, [filter, user]);

    const fetchMore = async () => {
        await wait(1000);
        setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    };


    const getProfile = () => {
        if (!user?._id) return;
        let bodyParms = {
            _id: user?._id,
        };
        // setIsLoading(true)
        dispatch(
            getUserProfile({
                data: bodyParms,
                callBack: (err, res) => {
                    // setIsLoading(false)
                    if (err) return;
                    if (res.status === "success") {
                        setGameEnergyLeft(res?.data?.energyBalance);
                        setBtncBalance(res?.data?.btncBalance);
                    }
                },
            })
        );
    };

    const onClaimBalance = (claimId) => {
        if (!isBitanicaLinked) return;
        if (claimLoading) return;
        dispatch(claimBalanceApi({
            data: { _id: claimId }, callBack: (err, res) => {
                setRowData(null);
                if (err) return;
                // setGameEnergyLeft(res?.data?.energyBalance); // manage energy used count
                // setBtncBalance(res?.data?.btncBalance);
                getProfile();
            }
        }));
    };
    const { screenSize: { dynamicHeight } } = useWindow();
    const elementRef = useRef(null); // Create a ref for the element
    // const scrollStopOn = (dynamicHeight - 354) > elementRef.current?.offsetHeight ? true : false;
    useEffect(() => {
        const calculateScrollStop = () => {
          if (elementRef.current) {
            const elementHeight = elementRef.current.offsetHeight;
            // console.log("Element height: $$$$$", elementHeight);
            // console.log("Dynamic height: $$$$$", dynamicHeight);
    
            setScrollStopOn(dynamicHeight - 128 > elementHeight);
            calculateScrollStop();
    
            // Optional: Listen for window resize to update the calculation dynamically
            window.addEventListener("resize", calculateScrollStop);
            return () => window.removeEventListener("resize", calculateScrollStop);
          }
        };
      }, [dynamicHeight,claimHistoryList ]);
    return (
        <div className={`${scrollStopOn && totalClaimHisotory <= 10 ? "no-minimize" : ""}`}>
            {linkAccount
                &&
                <LinkAccountPop
                    linkAccount={linkAccount}
                    setLinkAccount={() => {
                        setLinkAccount(false);
                        setTimeout(() => {
                            setRowData(null)
                        }, 100)
                    }}
                    rowData={rowData}
                />
            }
            {(loadingBitanicLink) || (loadingClaimHistory && filter.page === 1) && (
                <LoaderCstm />
            )}
            {!loadingClaimHistory && !loadingBitanicLink && !claimHistoryList?.length ?
                <div className="d-flex justify-content-center align-items-center">
                    <span className='text-white'>No data found!</span>
                </div>
                : null}
            <InfiniteScroll
                dataLength={claimHistoryList?.length}
                next={fetchMore}
                hasMore={claimHistoryList?.length < totalClaimHisotory}
                scrollThreshold={0}
                scrollableTarget="cryptoWalletWrp"
                initialScrollY={true}
                loader={
                    loadingClaimHistory && filter.page >= 2 && (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner className={styles.mSpinner} size="sm" />
                        </div>
                    )}

            >
                <ul ref={elementRef} id="cryptoWalletWrp" style={{
                    maxHeight: "calc(100vh - 354px)",
                    height: "auto",
                    overflowY: "scroll"
                }} className="list-unstyled ps-0 mb-0 allow-scroll">
                    {claimHistoryList?.map((item) => (
                        <li className="py-1">
                            <div
                                className={`${styles.cardCstm} ${item?.status !== "claimed" && item?.status !== "processing" && item?.status !== "rejected" ? styles.active : ""} position-relative px-3 py-2 cardCstm d-flex align-items-center gap-10 justify-content-between`}>
                                <span className={`${styles.wave} wave position-absolute`} style={{ bottom: 0, left: 0, zIndex: 0 }}>
                                    {wave}
                                </span>
                                <div className={`${styles.left} left d-flex align-items-center gap-10`}>
                                    <span className="flex-shrink-0">
                                        <img src={item?.coinIcon} style={{ width: 40, height: 40 }} />
                                    </span>
                                    <div className="content">
                                        <h6 className="m-0 text-white">{item?.coinSymbol}</h6>
                                        <p className="m-0 fw-bold">
                                            {item?.amount}
                                        </p>
                                    </div>
                                </div>
                                <div className={`${styles.center} center d-flex align-items-center`}>
                                    <span className="icn me-2">{lock}</span>
                                    <p className="m-0 fw-bold" style={{ lineHeight: "14px" }}>{item?.date_created_utc && moment(item?.date_created_utc).format("DD.MM.YY")} <br />
                                        {item?.date_created_utc && moment(item?.date_created_utc).format("HH:mm:ss")}</p>
                                </div>
                                <div className="right">
                                    <Button
                                        // disabled={item?.status === "claimed" || !isBitanicaLinked}
                                        onClick={(e) => {
                                            e?.preventDefault();
                                            if (!user?._id) return;
                                            if (item?.status === "claimed" || item?.status === "rejected" || item?.status === "processing") return;
                                            if (loadingBitanicLink) return;
                                            else if (!isBitanicaLinked) {
                                                setLinkAccount(true);
                                                setRowData(item);
                                            } else {
                                                setRowData(item);
                                                onClaimBalance(item?._id)
                                            }
                                        }
                                        }

                                        className={`${styles.btn} ${item?.status === "claimed" ? styles.claimed : item?.status === "processing" ? styles.pending : item?.status === "rejected" ? styles.rejected : ""} d-flex align-items-center justify-content-center`}>
                                        {claimLoading && item?._id === rowData?._id && <Spinner className="mx-1" size="sm" />}
                                        {item?.status === "claimed" ? "Claimed" :
                                            item?.status === "processing" ? "Pending" :
                                                item?.status === "rejected" ? "Rejected" :
                                                    "Claim"}</Button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </InfiniteScroll>

        </div >
    )
}

export default CryptoHistory

