import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import styles from "./FriendsSec.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { INVITE_TEL_LINK } from "../../../helper/api_helper";
import { generateTelInviteLink } from "../../../helper/common";
import { getUserProfile } from "../../../lib/store/actions";
import LoaderCstm from "../../../components/Loader";
import coin from "../../../assets/images/diamond.png";
import { useWindow } from "../../../hooks/useWindow";

const Friends = () => {
  const [data, setData] = useState({
    reffered: 0,
    earned: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useSelector(({ Auth }) => ({
    user: Auth.user,
    loading: Auth.profileLoading,
  }));

  useEffect(() => {
    if (!user?._id) return;
    let bodyParms = {
      _id: user?._id,
    };
    dispatch(
      getUserProfile({
        data: bodyParms,
        callBack: (err, res) => {
          if (err) return;
          if (res?.data) {
            setData({
              reffered: res?.data?.invitedUsers?.length,
              earned: res?.data?.referralEarning,
            });
          }
        },
      })
    );
  }, []);

  const onInviteFriends = () => {
    // if (user?.telegram_id) {
    const link = generateTelInviteLink(INVITE_TEL_LINK + user?.telegram_id);
    window.open(link, "_blank");
    // }
  };
  const [scrollStopOn, setScrollStopOn] = useState(false);
   
  const {
    screenSize: { dynamicHeight },
  } = useWindow();

  const elementRef = useRef(null); // Create a ref for the element

  // const scrollStopOn =
  //   dynamicHeight - 128 > elementRef.current?.offsetHeight ? true : false;

  useEffect(() => {
    const calculateScrollStop = () => {
      if (elementRef.current) {
        const elementHeight = elementRef.current.offsetHeight;
        console.log("Element height: $$$$$", elementHeight);
        console.log("Dynamic height: $$$$$", dynamicHeight);

        setScrollStopOn(dynamicHeight - 128 > elementHeight);
        calculateScrollStop();

        // Optional: Listen for window resize to update the calculation dynamically
        window.addEventListener("resize", calculateScrollStop);
        return () => window.removeEventListener("resize", calculateScrollStop);
      }
    };
  }, [dynamicHeight]);
  return (
    <>
      <section className={`${styles.FriendsSec} wallet position-relative`}>
        {loading && <LoaderCstm />}
        <Container>
          <Row>
            {/* <Col lg="12" className="my-2 no-minimize"> */}
            <Col lg="12" className="my-2">
              <div className="top text-center">
                <h5 className="m-0 themeClr fw-bold">Friends</h5>
              </div>
            </Col>
            <Col
              lg="12"
              className={`${styles.bottomSec} py-3 mt-3`}
              style={{ background: "#13131B" }}
            >
              <div className="inner px-3">
                <div className="py-2">
                  <div className="top">
                    <h5 className="m-0 fw-bold text-white">
                      Invite your friends and get more points!
                    </h5>
                  </div>
                </div>
                <div className="py-2">
                  <div
                    className={`${styles.cardCstm} p-3 d-flex align-items-start gap-10`}
                  >
                    <div className="icnWrp flex-shrink-0">{icn1}</div>
                    <div className="content">
                      <h6 className="m-0 pb-1 fw-bold text-white">
                        LEVEL 1 - {user?.isVipUser ? "25%" : "10%"}
                      </h6>
                      <p className="m-0">
                        Earn {user?.isVipUser ? "25%" : "10%"} of BTNC from every invited friends's winnings
                      </p>
                    </div>
                  </div>
                  
                </div>
                <div className="py-2">
                <div
                    className={`${styles.cardCstm} p-3 d-flex align-items-start gap-10`}
                  >
                    <div className="icnWrp flex-shrink-0">{icn1}</div>
                    <div className="content">
                      <h6 className="m-0 pb-1 fw-bold text-white">
                        LEVEL 2 - {user?.isVipUser ? "2.5%" : "1%"}
                      </h6>
                      <p className="m-0">
                        Earn {user?.isVipUser ? "2.5%" : "1%"} of BTNC fro the winnings of friends invited by
                        your referral
                      </p>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <h5 className="m-0 fw-bold pb-2">Your statistics</h5>
                  <div className={`${styles.cardCstm} mt-1 px-3 py-2 `}>
                    <div className="py-2 d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <Button
                          onClick={() => navigate(`/refferals`)}
                          className="border-0 p-0"
                          variant="transparent"
                        >
                          Refferals <span className="icn ms-1">{arrow}</span>
                        </Button>
                      </div>
                      <div className="right">
                        <div className="d-flex align-items-center">
                          <p className="m-0 text-white">{data.reffered || 0}</p>
                          <span className="icn ms-2">{icn1}</span>
                        </div>
                      </div>
                    </div>
                    <hr className=" my-1" />
                    <div className="py-2 d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <Button
                          onClick={() => navigate(`/earnings`)}
                          className="border-0 p-0"
                          variant="transparent"
                        >
                          Earned <span className="icn ms-1">{arrow}</span>
                        </Button>
                      </div>
                      <div className="right">
                        <div className="d-flex align-items-center">
                          <p className="m-0 text-white">{data.earned || 0}</p>
                          <span className="icn ms-2">
                            <img
                              src={coin}
                              alt=""
                              className="img-fluid object-contain"
                              style={{ height: 25 }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <div className={`${styles.btnWrpper} px-3 mt-3`}>
                    <Button
                      onClick={() => onInviteFriends()}
                      className="d-flex fw-bold align-items-center justify-content-center commonBtn w-100"
                    >
                      Invite Friends
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Friends;

const icn1 = (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_1108_8050)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 13.5C0 6.04336 6.04336 0 13.5 0C20.9566 0 27 6.04336 27 13.5C27 20.9566 20.9566 27 13.5 27C6.04336 27 0 20.9566 0 13.5Z"
        fill="#4096EC"
      />
    </g>
    <g clip-path="url(#clip0_1108_8050)">
      <path
        d="M4.92207 17.629C4.97832 17.3056 5.18926 17.0103 5.48457 16.8696C6.46895 16.3774 8.62051 15.6603 8.62051 15.6603V14.9431L8.56426 14.9009C8.15645 14.5915 7.86113 14.1134 7.77676 13.5931L7.7627 13.5228H7.70645C7.36895 13.5228 7.0877 13.3118 6.9752 13.0306C6.90488 12.904 6.8627 12.7634 6.8627 12.6087C6.8627 12.5103 6.87676 12.4118 6.91895 12.3134C6.93301 12.2149 7.00332 12.1165 7.07363 12.0603L7.27051 11.9337L7.22832 11.7228C6.8627 10.1618 8.08613 8.7415 9.71738 8.71338C9.75957 8.71338 9.80176 8.71338 9.82988 8.71338C9.87207 8.71338 9.91426 8.71338 9.94238 8.71338C11.5877 8.72744 12.7971 10.1478 12.4455 11.7087L12.3893 11.9196L12.5861 12.0462C12.6705 12.1024 12.7268 12.2009 12.7408 12.2993C12.783 12.3978 12.7971 12.4962 12.7971 12.5946C12.7971 12.7493 12.7549 12.8899 12.6846 13.0165C12.5721 13.3118 12.2768 13.5087 11.9533 13.5087H11.8971L11.883 13.579C11.7986 14.0993 11.5174 14.5774 11.0955 14.8868L11.0393 14.929V15.6462C11.0393 15.6462 13.1908 16.3634 14.1752 16.8556C14.4705 17.0103 14.6814 17.2915 14.7377 17.6149C14.9064 18.5993 14.9346 20.2587 14.9346 20.2587H4.71114C4.7252 20.2728 4.75332 18.6134 4.92207 17.629Z"
        fill="white"
      />
      <path
        d="M14.4978 16.2429C13.9353 15.9617 13.0493 15.6242 12.3462 15.371C12.4446 15.1742 12.6134 15.0054 12.8103 14.907C13.7946 14.4148 15.9462 13.6976 15.9462 13.6976V12.9804L15.8899 12.9382C15.4821 12.6289 15.1868 12.1507 15.1024 11.6304L15.0884 11.5601H15.0321C14.6946 11.5601 14.4134 11.3492 14.3009 11.0679C14.2306 10.9414 14.1884 10.8007 14.1884 10.646C14.1884 10.5476 14.2024 10.4492 14.2446 10.3507C14.2587 10.2523 14.329 10.1539 14.3993 10.0976L14.5962 9.97105L14.554 9.76011C14.1884 8.19917 15.4118 6.77886 17.0431 6.75073C17.0853 6.75073 17.1274 6.75073 17.1556 6.75073C17.1978 6.75073 17.2399 6.75073 17.2681 6.75073C18.8993 6.79292 20.1087 8.19917 19.7571 9.76011L19.7009 9.97105L19.8978 10.0976C19.9821 10.1539 20.0384 10.2523 20.0524 10.3507C20.0946 10.4492 20.1087 10.5476 20.1087 10.646C20.1087 10.8007 20.0665 10.9414 19.9962 11.0679C19.8837 11.3632 19.5884 11.5601 19.2649 11.5601H19.2087L19.1946 11.6304C19.1243 12.1507 18.8431 12.6148 18.4212 12.9382L18.3649 12.9804V13.6976C18.3649 13.6976 20.5165 14.4148 21.5009 14.907C21.7962 15.0617 22.0071 15.3429 22.0634 15.6664C22.2321 16.6507 22.2603 18.3101 22.2603 18.3101H15.5384C15.5103 18.0289 15.4821 17.7617 15.4399 17.5226C15.3415 16.9601 14.9899 16.496 14.4978 16.2429Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1108_8050"
        x="0"
        y="0"
        width="27"
        height="28.2857"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.28571" />
        <feGaussianBlur stdDeviation="1.28571" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1108_8050"
        />
      </filter>
      <clipPath id="clip0_1108_8050">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="matrix(-1 0 0 1 22.5015 4.49829)"
        />
      </clipPath>
    </defs>
  </svg>
);

const arrow = (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.196792 13.9598C-0.0656646 13.7012 -0.0654811 13.2777 0.1972 13.0194L5.07001 8.22653C5.47603 7.82717 5.47603 7.17259 5.07001 6.77323L0.197082 1.98028C-0.0655343 1.72197 -0.0657176 1.29864 0.196674 1.04011C0.453599 0.78696 0.866208 0.787105 1.12295 1.04043L6.93738 6.77745C7.34202 7.17671 7.34186 7.82981 6.93702 8.22886L1.12307 13.9597C0.866221 14.2128 0.453689 14.2129 0.196792 13.9598Z"
      fill="white"
    />
  </svg>
);
