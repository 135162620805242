import React, { useEffect, useRef, useState } from 'react'
import styles from "./Leaderboard.module.scss"
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { gameLeaderBoard } from '../../../lib/store/actions';
import { useNavigate } from 'react-router-dom';
import LoaderCstm from '../../../components/Loader';
import useLbCountdown from '../../../hooks/userLbCountdown';
import { formatNumber } from '../../../helper/common';
import { useWindow } from '../../../hooks/useWindow';

const LeaderBoard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, gameLeaderboardList, loading, totalGameLeaderboard } = useSelector(({ Auth, Game }) => ({
        user: Auth.user,
        loading: Game.loading,
        gameLeaderboardList: Game.gameLeaderboardList,
        totalGameLeaderboard: Game.totalGameLeaderboard,
    }));
    const [myRank, setMyRank] = useState(null);

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        order: -1,
        orderBy: "date_created_utc",
        search: "",
        status: "",
        filter: ""
    });


    useEffect(() => {
        dispatch(gameLeaderBoard({
            data: { ...filter }, callBack: (err, res) => {
                if (err) return;
                if (res?.myRank) {
                    setMyRank(res?.myRank);
                }
            }
        }));
    }, [filter, user]);

    const onTimerEnd = () => {
        try {
            //console.info("Leaderbord Reload")
            dispatch(gameLeaderBoard({
                data: { ...filter }, callBack: (err, res) => {
                    if (err) return;
                    if (res?.myRank) {
                        setMyRank(res?.myRank);
                    }
                }
            }));
        } catch (err) {

        }
    }

    const timer = useLbCountdown(onTimerEnd);

    const setFilterLastWeek = () => {
        setFilter(pre => ({ ...pre, filter: pre.filter ? "" : "last" }));
    }
    
    useEffect(() => {
        // 
        document.body.classList.add('includeHeader');

        // Cleanup: Remove the class when the component unmounts asdfasdf
        return () => {
            document.body.classList.remove('includeHeader');
        };
    }, []);
    const { screenSize: { dynamicHeight } } = useWindow();
    const elementRef = useRef(null); // Create a ref for the element
    const scrollStopOn = (dynamicHeight - 230) > elementRef.current?.offsetHeight ? true : false;

    return (
        <>
            <section className={`${styles.leaderBoard} leaderBoard ${scrollStopOn && totalGameLeaderboard <= 10 ? "no-minimize" : ""}`}>
                <Container>
                    <Row>
                        <Col lg="12" className='my-2'>
                            <div className={`${styles.cardCstm} py-2 cardCstm`}>
                                <div className="px-2 mb-2">

                                    <Button onClick={() => navigate("/game")} className="border-0 p-0" variant="transparent">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 20" fill="none">
                                            <path d="M2.91436 9.76907L12.1877 2.01034C12.4158 1.81953 12.4158 1.5135 12.1877 1.32268C11.9596 1.13186 11.5939 1.13186 11.3658 1.32268L1.67935 9.42704C1.45128 9.61785 1.45128 9.92388 1.67935 10.1147L11.3658 18.2155C11.4777 18.3091 11.6283 18.3595 11.7746 18.3595C11.9209 18.3595 12.0715 18.3127 12.1834 18.2155C12.4115 18.0246 12.4115 17.7186 12.1834 17.5278L2.91436 9.76907Z" fill="white" stroke="white" stroke-width="2.26051" />
                                        </svg>
                                    </Button>
                                </div>
                                <div
                                    className={`${styles.top} top pb-3 px-2 text-center d-flex align-items-center justify-content-between`}
                                >
                                    <div
                                        className={`${styles.timer} themeClr p-1 timer d-flex align-items-center`}
                                    >
                                        <span className="icn me-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.42155 8.91246C5.62927 8.91246 4.98714 8.34784 4.98714 7.65122C4.98714 7.10257 5.38781 6.64095 5.94341 6.46732V4.28789C5.94341 4.05582 6.15714 3.86748 6.42155 3.86748C6.68596 3.86748 6.89968 4.05582 6.89968 4.28789V6.46732C7.45528 6.64095 7.85596 7.10257 7.85596 7.65122C7.85596 8.34784 7.21382 8.91246 6.42155 8.91246ZM6.8949 2.20683L6.89968 1.34498H8.81223C9.07664 1.34498 9.29037 1.15706 9.29037 0.924566C9.29037 0.692076 9.07664 0.50415 8.81223 0.50415H4.03086C3.76645 0.50415 3.55272 0.692076 3.55272 0.924566C3.55272 1.15706 3.76645 1.34498 4.03086 1.34498H5.94341V2.18581L5.9482 2.20683C2.73751 2.4204 0.205765 4.77305 0.205765 7.65122C0.205765 10.6698 2.98852 13.1166 6.42155 13.1166C9.85457 13.1166 12.6373 10.6698 12.6373 7.65122C12.6373 4.77305 10.1056 2.4204 6.8949 2.20683Z" fill="#54B3F5" />
                                            </svg>
                                        </span>
                                        {`${timer.hours}:${timer.minutes}:${timer.seconds}`}
                                    </div>
                                    <h5
                                        className="m-0 d-inline-block fw-bold"
                                        style={{ color: "#54B3F5" }}
                                    >
                                        Leaderboard
                                    </h5>
                                    <div
                                        className={`${styles.tab} ${filter?.filter ? styles.active : ""} p-1 box rounded text-white px-2`}
                                        onClick={() => setFilterLastWeek()}
                                    >
                                        Last Week
                                    </div>
                                </div>
                                {loading && (
                                    <LoaderCstm />
                                )}
                                <div className="table-responsive allow-scroll" style={{ overflowY: "scroll", height: "calc(100vh - 230px)" }}>

                                    <table ref={elementRef} className="table text-center m-0">
                                        <thead className='no-minimize'>
                                            <tr>
                                                <th className="border-0 bg-transparent fw-normal">Place</th>
                                                <th className="border-0 bg-transparent fw-normal">
                                                    Username
                                                </th>
                                                <th className="border-0 bg-transparent fw-normal">Points</th>
                                                <th className="border-0 bg-transparent fw-normal">Prizes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gameLeaderboardList?.map((item, index) => (
                                                <tr key={"leader" + index} >
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className="m-0 fw-normal text-white">{item?.rank} </h6>
                                                    </td>
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className="m-0 fw-normal text-white">{item?.userDetails?.telegram_username ? item?.userDetails?.telegram_username : item?.userDetails?.first_name || ""}</h6>
                                                    </td>
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className={`m-0 fw-normal ${styles.points}`}>{item?.points || 0}</h6>
                                                    </td>
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className="m-0 fw-normal text-white d-flex align-items-center"> <span className="icn me-1">
                                                            <img src={item?.prizeIcon} alt="" className="img-fluid object-fit-contain" style={{ height: 15 }} /></span> {formatNumber(item?.prizes) || 0}</h6>
                                                    </td>
                                                </tr>
                                            ))}
                                            {myRank ?
                                                <tr className={`${styles.active}`}>
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className="m-0 fw-normal text-white">{myRank?.rank}. </h6>
                                                    </td>
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className="m-0 fw-normal text-white">{myRank?.userDetails?.telegram_username ? myRank?.userDetails?.telegram_username : myRank?.userDetails?.first_name || ""}</h6>
                                                    </td>
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className={`m-0 fw-normal ${styles.points}`}>{myRank?.points || 0}</h6>
                                                    </td>
                                                    <td className="border-0 bg-transparent px-2 py-3">
                                                        <h6 className="m-0 fw-normal text-white d-flex align-items-center"> <span className="icn me-1">
                                                            <img src={myRank?.prizeIcon} alt="" className="img-fluid object-fit-contain" style={{ height: 15 }} /></span> {myRank?.prizes || 0}</h6>
                                                    </td>
                                                </tr>
                                                : null}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container >
            </section >
        </>
    )
}

export default LeaderBoard