import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoaderCstm from "../Loader";
import { wait } from "../../helper/common";
import SplashScreen from "../Splash";

// PrivateRoute component as a wrapper for protected routes
const PrivateRoute = ({ sessionLoader, loginLoader, gameLoader, timeoutDone,children }) => {

  const { user, loading } = useSelector(({ Auth }) => Auth);
  //console.info("sessionLoader:", sessionLoader);
  //console.info("loginLoader:", loginLoader);
  //console.info("gameLoader:", gameLoader);
  //console.info("Redux loading:", loading);
  //console.info("timeoutDone:", timeoutDone);



  
  // While checking auth, show a loading indicator
  // While checking auth, show a loading indicator

  if ((sessionLoader || loginLoader || gameLoader || loading) && !timeoutDone) {
    //console.info(timeoutDone,"TIMEOUTTTT")
    return <SplashScreen />;
  }
  // wait(500);
  // If authenticated, render the child component (protected content)
  if (user?._id) {
    return children;
  }

  // If not authenticated, redirect to the login page
  return <Navigate to="/login" />;
};

export default PrivateRoute;
